import { render, staticRenderFns } from "./TitleRequest.vue?vue&type=template&id=6d1e6ba2&"
import script from "./TitleRequest.vue?vue&type=script&lang=ts&"
export * from "./TitleRequest.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports