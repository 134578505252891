














import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
  }
})
export default class ThankYou extends Vue {
  /* eslint-disable */
}
