































































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../config";
import { authHeader } from "../../services/auth";
import ViewDocument from "../../common-components/ViewDocument.vue";
@Component({
  components: {
    ViewDocument,
  },
})
export default class TitleRequest extends Vue {
  /* eslint-disable */
  public allDocs: any = [];
  public sessionObject: any = null;
  public error = false;
  public titleInfo: any = {};

  public async verifyToken() {
    this.callLoader(true, null);
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "authentication/loanDetaillsforLoanCard/" +
          this.$route.query.id
      );

      if (response.status === 201) {
        this.$snotify.success(response.data.message);
        this.titleInfo = response.data.obj;
        this.returnDocs();
      } else {
        this.$snotify.error(response.data.message);
        this.$router.push({ path: "/thankyou" });
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async returnDocs() {
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "processor/returnDocsToBeUploaded/" +
          this.titleInfo.loanId
      );
      if (response.status === 201) {
        this.allDocs = response.data.allDocs;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async uploadFile(event, doc) {
    this.callLoader(true, null);
    try {
      if (event.target.files.length <= 0) return;
      const formData: any = new FormData();
      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });
      let loanId: any = this.titleInfo.loanId;
      formData.append("loanId", loanId);
      let taskId: any = 6;
      formData.append("docId", doc.documentId);
      formData.append("docName", doc.docName);
      formData.append("taskId", taskId);
      const response = await Axios.post(
        BASE_API_URL + "processor/uploadTitleRequestDocs",
        formData
      );

      if (response.status === 201) {
        doc.uploaded = true;
        this.$snotify.success(response.data.message);
      }
      this.returnDocs();
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public async viewDocument(document) {
    try {
      this.$refs.ref["showFileForNonAuthenticateUser"](document);
    } catch (error) {
      console.log(error);
    }
  }

  public async saveAndValidate() {
    this.callLoader(true, null);
    let notUploaded = this.allDocs.filter(
      (E) => E.docStatus != "Uploaded"
    ).length;

    if (notUploaded) {
      this.error = true;
      this.callLoader(false, null);
      return;
    }
    let response = await Axios.get(
      BASE_API_URL +
        "processor/sendEmailAnDeleteTitleToken/" +
        this.titleInfo.loanId +
        "/" +
        this.$route.query.id
    );
    if (response.status === 201) {
      this.$router.push("/thankyou");
      this.$snotify.success("Thank You");
    }
    this.callLoader(false, null);
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
    this.sessionObject = this.$store.state.sessionObj;
  }

  mounted() {
    this.verifyToken();
  }
}
